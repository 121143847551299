<template>
  <v-bottom-sheet
    v-model="open"
    persistent
    :fullscreen="full"
    :scrollable="full"
  >
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="open && on"
        @click="$emit('check')"
        color="white"
        class="primary--text"
        style="min-width: 164px"
      >
        Submit
      </v-btn>
    </template>
    <v-card height="580px">
      <v-card-text>
        <v-toolbar flat dark color="primary" max-height="100px" class="mb-2">
          <v-toolbar-title> Confirmation </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              :text="!full"
              :icon="full"
              v-for="(item, i) in actions"
              :key="i"
              @click="item.action"
            >
              <v-icon left> {{ `mdi-content-save${item.icon}` }} </v-icon>
              {{ full ? '' : item.name }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row>
          <v-col cols="12" md="6">
            <v-row class="text-start">
              <v-col cols="6" :class="full ? 'pl-8' : 'pl-10'">
                <p>
                  <span>
                    {{ event === 'releases' ? 'Release' : 'Tag' }} Date:
                  </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.createdDate && getFormatted(data.createdDate)
                  }}</span>
                </p>
                <p v-if="!!data.number">
                  <span class="font-weight-thin"> Tag Number: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.number
                  }}</span>
                </p>
                <p v-if="getPriorTagNumber(data)">
                  <span class="font-weight-thin"> Prior Tag Number: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getPriorTagNumber(data)
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> Entered Date: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.enteredDate && getFormatted(data.enteredDate)
                  }}</span>
                </p>
                <p v-if="!!data.daysAtLarge">
                  <span class="font-weight-thin"> Days At Large: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.daysAtLarge
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> Angler: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getFullName(data.angler)
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> Boat: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getBoatName(data)
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> Captain: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getFullName(data.captain)
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> First Mate: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getFullName(data.firstMate)
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> Second Mate: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getFullName(data.secondMate)
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> Location: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getLocationDescription(data)
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> Notes: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getNotes(data)
                  }}</span>
                </p>
              </v-col>
              <v-col cols="6" :class="!full && 'pl-10'">
                <div v-if="event === 'releases'">
                  <p>
                    <span class="font-weight-thin"> Releases: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-for="(fish, i) in data.capture.fish"
                      :key="i"
                    >
                      {{ fishList(fish, i) }}
                    </span>
                  </p>
                  <p>
                    <span class="font-weight-thin"> Lines In: </span>
                    <span class="font-weight-black text-uppercase">{{
                      data.relLines
                    }}</span>
                  </p>
                  <p>
                    <span class="font-weight-thin"> Lines Out: </span>
                    <span class="font-weight-black text-uppercase">{{
                      data.relLines0
                    }}</span>
                  </p>
                  <p>
                    <span class="font-weight-thin"> Total Lines: </span>
                    <span class="font-weight-black text-uppercase">{{
                      data.relNumLi
                    }}</span>
                  </p>
                </div>
                <div v-else>
                  <p>
                    <span class="font-weight-thin"> Species: </span>
                    <span class="font-weight-black text-uppercase">{{
                      specie
                    }}</span>
                  </p>
                  <p v-if="event !== 'juvenile'">
                    <span class="font-weight-thin"> Fish Condition: </span>
                    <span class="font-weight-black text-uppercase">{{
                      condition
                    }}</span>
                  </p>
                  <p v-if="event !== 'juvenile'">
                    <span class="font-weight-thin"> Fish Length: </span>
                    <span class="font-weight-black text-uppercase">{{
                      getFishStat(data.capture.fish.length)
                    }}</span>
                  </p>
                  <p v-if="event !== 'juvenile'">
                    <span class="font-weight-thin"> Fish Weight: </span>
                    <span class="font-weight-black text-uppercase">{{
                      getFishStat(data.capture.fish.weight)
                    }}</span>
                  </p>
                  <p>
                    <span> Fight Time: </span>
                    <span class="font-weight-black text-uppercase">{{
                      getFightTime()
                    }}</span>
                  </p>
                </div>
                <p>
                  <span class="font-weight-thin"> Gear: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getGearName(data)
                  }}</span>
                </p>
                <p v-if="event !== 'juvenile'">
                  <span class="font-weight-thin"> Bait: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getBaitName(data)
                  }}</span>
                </p>
                <p>
                  <span class="font-weight-thin"> Ocean: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getOceanName(data)
                  }}</span>
                </p>
                <p v-if="event !== 'juvenile'">
                  <span class="font-weight-thin"> Tournament: </span>
                  <span class="font-weight-black text-uppercase">{{
                    getTournamentName(data)
                  }}</span>
                </p>
                <p v-if="event !== 'juvenile'">
                  <span class="font-weight-thin"> Shark Encounter: </span>
                  <span class="font-weight-black text-uppercase">{{
                    data.sharkEncounter ? 'YES' : 'NO'
                  }}</span>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" :class="full ? 'pr-8' : 'pr-10'">
            <fishing-locations
              ref="location"
              :fabPosition="false"
              :fabNavigation="false"
              :input="input"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { formatDate } from '../../../../helpers/handler';
import FishingLocations from '../../../map/FishingLocations.vue';
import {
  CrewViewModel,
  CaptureViewModel,
  CoordinatesViewModel
} from '../../viewmodels/index';
export default {
  components: { FishingLocations },
  name: 'confirmation-view',
  props: {
    data: {
      type: Object,
      default: () => INPUT
    },
    event: {
      type: String,
      default: () => ''
    },
    isNew: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({
    createActions: [],
    updateActions: [],
    open: false,
    overlay: false,
    input: INPUT
  }),
  mounted() {
    this.createActions = [
      { name: 'Save', action: () => this.save(), icon: '' },
      {
        name: 'Save & New',
        action: () => this.save('new'),
        icon: '-edit'
      },
      {
        name: 'Save & Copy',
        action: () => this.save('copy'),
        icon: '-all'
      },
      {
        name: 'Back',
        action: () => (this.open = false),
        icon: '-move'
      }
    ];

    this.updateActions = [
      { name: 'Update', action: () => this.save(), icon: '' },
      {
        name: 'Back',
        action: () => (this.open = false),
        icon: '-move'
      }
    ];
  },
  watch: {
    open: {
      handler: 'init'
    }
  },
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    specie() {
      return this.data?.capture?.fish?.specie?.name || 'NOT SPECIFIED';
    },
    condition() {
      return this.data?.capture?.fish?.condition?.name || 'NOT SPECIFIED';
    },
    actions() {
      return this.isNew ? this.createActions : this.updateActions;
    }
  },
  methods: {
    init(opened) {
      if (!opened) {
        this.overlay = false;
        return;
      }

      setTimeout(() => {
        this.input = this.data;
      }, 100);
    },
    getBoatName(data) {
      return data?.boat?.name || data?.boatName || 'Not Specified';
    },
    getGearName(data) {
      return data?.capture?.gear?.name || 'Not Specified';
    },
    getBaitName(data) {
      return data?.capture?.bait?.name || 'Not Specified';
    },
    getOceanName(data) {
      return data?.capture?.ocean || 'Not Specified';
    },
    getTournamentName(data) {
      const { name } = data?.capture?.tournament || {};
      return name && name !== 'NULL' ? name : 'Not Specified';
    },
    getFullName(item) {
      return item && item?.firstName && item?.lastName
        ? `${item.firstName} ${item.lastName}`
        : 'Not Specified';
    },
    getFightTime() {
      const hours = this.data?.capture?.fish?.fight?.hours;
      const minutes = this.data?.capture?.fish?.fight?.minutes;
      return hours || minutes
        ? `${hours || '00'}:${minutes || '00'} h`
        : 'Not Specified';
    },
    getFishStat(data) {
      const { value, unit, determination, type } = data || {};
      return `${value || 0} ${unit || ''} ${type || ''} ${determination || ''}`;
    },
    fishList(fish, i) {
      let text = `${fish?.qty} ${fish?.name}`;
      const size = this.data?.capture?.fish?.length || 0;
      !!size && size !== 1 && i < size - 1 && (text = text.concat(' | '));
      return text;
    },
    getLocationDescription(data) {
      return data?.coordinates?.description || 'Not Specified';
    },
    getNotes(data) {
      return data?.capture?.notes || 'Not Specified';
    },
    save(value) {
      this.overlay = true;
      this.$emit('save', value);
    },
    getFormatted(date) {
      return formatDate(date);
    },
    getPriorTagNumber(data) {
      return data?.priorTag?.priorTagNumber;
    }
  }
};

const INPUT = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel()
};
</script>

<style scoped>
.v-card .v-card__text {
  padding: 0;
}
</style>
