<template>
  <v-card title>
    <v-dialog
      v-model="onEditEntry"
      max-width="800px"
      class="tag-dialog"
      scrollable
      :fullscreen="full"
    >
      <record-fish ref="recordFish" @cancel="onEditEntry = !onEditEntry" />
    </v-dialog>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="$emit('cancel', 'close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Edit User Details</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <new-user
        ref="newUser"
        hideToolbar
        @close="$emit('cancel', 'close')"
        @done="$emit('done')"
      />
      <v-row class="mt-5">
        <records-view ref="recordsView" />
      </v-row>
    </v-card-text>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import RecordsView from '@/components/appComponents/RecordsView.vue';
import RecordFish from './RecordFish.vue';
import NewUser from './sections/NewUser.vue';

export default {
  name: 'edit-user-info',
  components: {
    RecordsView,
    RecordFish,
    NewUser
  },
  data: () => ({
    overlay: false,
    onEditEntry: false,
    userId: null,
    emailRule: [v => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid']
  }),
  mounted() {
    this.$emit('loaded');
  },
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    async initialize(data) {
      this.userId = data?.id || data?._id;
      this.$refs.recordsView && this.$refs.recordsView.loadEntries(this.userId);
      if (this.$refs.newUser) {
        await this.$refs.newUser.init(this.userId);
      }
    }
  }
};
</script>

<style>
.ma-2.deactivate {
  cursor: pointer;
}
</style>
