<template>
  <v-container fluid class="down-top-padding">
    <record-fish ref="recordFish" />
    <v-dialog
      v-model="linkView"
      scrollable
      persistent
      max-width="860px"
      :fullscreen="full"
    >
      <users-linker
        @close="closeLinkView"
        :contactUser="contact"
        @done="connectionDone"
      ></users-linker>
    </v-dialog>
    <v-dialog
      v-model="onUserEdit"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
      max-width="860px"
      :fullscreen="full"
    >
      <edit-user-info
        ref="editUserInfo"
        @cancel="closeEditDialog"
        @loaded="initEditDialog"
        @submit="submitUserInfo"
        @done="connectionDone"
      ></edit-user-info>
    </v-dialog>
    <v-dialog
      v-model="showRecords"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      persistent
    >
      <template>
        <v-card title>
          <v-toolbar flat dark color="primary" max-height="100px">
            <v-btn icon dark @click="closeRecords()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Records</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <records-view ref="recordsView" @loaded="initRecordsView" />
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
    <tab-menu :tabs="tabs" @change="changeTabs">
      <template v-slot:tab-items>
        <v-tab-item
          v-for="(tab, index) in [
            'all',
            'active',
            'inactive',
            'doNotMail',
            'doNotNotify',
            'virtuous'
          ]"
          :key="tab"
        >
          <list-view
            ref="listView"
            :title="tabs[index]"
            :headers="tab === 'virtuous' ? virtuous_headers : headers"
            :records="members[tab]"
            :single-select="true"
            :selected-rows="selectedRows"
            :async-loading="loading"
            :footer-options="footer[tab]"
            :total-docs="total[`${tab}Docs`]"
            @onPaginate="(v) => (options[tab] = v)"
            @onClick="tab === 'virtuous' ? null : presentRecords($event)"
            :exportable="tab !== 'virtuous'"
            @export="(v) => exportFile(v, searchBox[tab], tab)"
            @reload="loadDisplayedTable"
            searchable
            addUser
            :hideHeader="tab === 'virtuous'"
            :filterable="tab === 'virtuous'"
          >
            <template slot="dropdown">
              <v-container id="dropdown-selector">
                <v-overflow-btn
                  :items="tableModes"
                  target="#dropdown-selector"
                  v-model="tableSelection"
                  hide-details
                  class="dropdown"
                ></v-overflow-btn>
              </v-container>
            </template>
            <template slot="tags" slot-scope="{ row }">
              <v-chip
                color="orange lighten-2 px-1"
                text-color="white"
                label
                x-small
                class="font-weight-bold"
                v-if="row['archived']"
              >
                <span>Archived</span>
              </v-chip>
              <v-chip
                color="primary lighten-2 px-1"
                text-color="white"
                label
                x-small
                class="font-weight-bold"
                v-else-if="row['virtuousId']"
              >
                <span>Synced</span>
              </v-chip>
            </template>
            <template slot="list-item" slot-scope="{ row }">
              <div v-if="tab === 'virtuous'">
                <v-tooltip left v-if="row.connectionId">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="red"
                      fab
                      dark
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="() => disconnect(row)"
                    >
                      <v-icon> mdi-link-variant-off </v-icon>
                    </v-btn>
                  </template>
                  <span>Disconnect user</span>
                </v-tooltip>

                <v-tooltip left v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="blue"
                      fab
                      dark
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="() => openLinkView(row)"
                    >
                      <v-icon> mdi-link-variant </v-icon>
                    </v-btn>
                  </template>
                  <span>Connect user</span>
                </v-tooltip>

                <v-tooltip top v-if="row.removed">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="green"
                      fab
                      dark
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="backInSystem(row)"
                    >
                      <v-icon> mdi-eye-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Get Back</span>
                </v-tooltip>

                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="red"
                      fab
                      dark
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="notInSystem(row)"
                    >
                      <v-icon> mdi-eye-minus </v-icon>
                    </v-btn>
                  </template>
                  <span>Not In System</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="purple"
                      fab
                      dark
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="editUserInfo(row)"
                    >
                      <v-icon> mdi-account-edit </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="blue"
                      fab
                      dark
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="openDialog('Tags', row)"
                    >
                      <v-icon> mdi-tag-text </v-icon>
                    </v-btn>
                  </template>
                  <span>Tag</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="green"
                      @click.stop="openDialog('Releases', row)"
                      fab
                      dark
                      class="mr-1"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon> mdi-fish </v-icon>
                    </v-btn>
                  </template>
                  <span>Release</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="warning"
                      fab
                      dark
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="openDialog('Recaptures', row)"
                    >
                      <v-icon> mdi-fishbowl </v-icon>
                    </v-btn>
                  </template>
                  <span>Recapture</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      x-small
                      color="red"
                      fab
                      dark
                      class="mr-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="deleteAction(row)"
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
            </template>
            <template slot="full-name" slot-scope="{ row }">
              <span>{{ `${row.firstName} ${row.lastName}` }}</span>
            </template>
            <template slot="full-address" slot-scope="{ row }">
              <span>{{ address(row) }}</span>
            </template>
            <template
              slot="header"
              slot-scope="{ row }"
              v-if="tab === 'virtuous'"
            >
              <th
                class="text-left pl-5 header_size pb-5"
                v-for="(head, i) in row.headers"
                :key="i"
              >
                <div class="d-flex head_div">
                  <span
                    class="caption font-weight-bold grey--text text--darken-2 pr-1"
                  >
                    {{ head.text }}
                  </span>
                  <v-btn
                    icon
                    v-if="!!head['sort']"
                    @click="sortBy(head)"
                    x-small
                  >
                    <v-icon v-if="head['order']">mdi-arrow-down</v-icon>
                    <v-icon v-else>mdi-arrow-up</v-icon>
                  </v-btn>
                </div>
              </th>
            </template>
          </list-view>
        </v-tab-item>
      </template>
    </tab-menu>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ListView from '@/components/appComponents/ListView.vue';
import EditUserInfo from '@/views/users/forms/EditUserInfo.vue';
import TabMenu from '@/components/appComponents/TabMenu.vue';
import RecordsView from '../../components/appComponents/RecordsView.vue';
import RecordFish from './forms/RecordFish.vue';
import UsersLinker from './UsersLinker.vue';
import {
  formatDate,
  formatStr,
  getAddress,
  notifyConfirmation,
  notifyMessage,
  getFileDeprecated,
  formatPhone,
  getUserInfo,
  notifyError
} from '../../helpers/handler';
export default {
  name: 'manage-users',
  components: {
    ListView,
    EditUserInfo,
    TabMenu,
    RecordsView,
    RecordFish,
    UsersLinker
  },
  async created() {
    this.overlay = true;
    this.loading = false;
    this.overlay = false;
  },
  data: () => ({
    overlay: false,
    loading: true,
    linkView: false,
    tabs: [
      'All Users',
      'Active Members',
      'Inactive Members',
      '"DoNotMail" Users',
      '"DoNotNotify" Users',
      'Virtuous contacts'
    ],
    page: {
      title: 'Manage Users'
    },
    headers: HEADERS,
    virtuous_headers: VIRTUOUS_HEADERS,
    autoComplete: {
      itemValue: 'fullName',
      itemText: 'fullName'
    },
    search: {
      all: [],
      active: [],
      inactive: [],
      doNotMail: [],
      doNotNotify: [],
      virtuous: []
    },
    footer: {
      all: {
        pageCount: 0,
        showFirstLastPage: true
      },
      active: {
        pageCount: 0,
        showFirstLastPage: true
      },
      inactive: {
        pageCount: 0,
        showFirstLastPage: true
      },
      doNotMail: {
        pageCount: 0,
        showFirstLastPage: true
      },
      doNotNotify: {
        pageCount: 0,
        showFirstLastPage: true
      },
      virtuous: {
        pageCount: 0,
        showFirstLastPage: true
      }
    },
    total: {
      allDocs: 0,
      activeDocs: 0,
      inactiveDocs: 0,
      doNotMailDocs: 0,
      doNotNotifyDocs: 0,
      virtuousDocs: 0
    },
    onUserEdit: false,
    showRecords: false,
    selectedRow: {},
    selectedRows: [],
    members: {
      all: [],
      active: [],
      inactive: [],
      doNotMail: [],
      doNotNotify: [],
      virtuous: []
    },
    selectedId: null,
    searchBox: {
      all: null,
      active: null,
      inactive: null,
      doNotMail: null,
      doNotNotify: null,
      virtuous: null
    },
    options: {
      all: null,
      active: null,
      inactive: null,
      doNotMail: null,
      doNotNotify: null,
      virtuous: null
    },
    tab: 0,
    contact: null,
    sort: null,
    order: false,
    tableModes: [
      { value: 'all', text: 'All' },
      { value: 'not_synced', text: 'Not Synced' },
      { value: 'synced', text: 'Synced' },
      { value: 'archived', text: 'Archived' },
      { value: 'removed', text: 'Not In System' },
      { value: 'non_member', text: 'Non Members' }
    ],
    tableSelection: 'not_synced'
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapActions('users', [
      'getUsers',
      'saveUserInfo',
      'deleteUser',
      'disconnectAccounts',
      'hideContact',
      'exposeContact'
    ]),
    ...mapActions('entry', [
      'addTag',
      'editTag',
      'addRelease',
      'editRelease',
      'addRecapture',
      'editRecapture'
    ]),
    address(value) {
      return getAddress(value);
    },
    presentRecords(item) {
      this.selectedId = item._id;
      this.showRecords = true;
      this.$refs.recordsView && this.$refs.recordsView.loadEntries(item._id);
    },
    initRecordsView() {
      this.$refs.recordsView.loadEntries(this.selectedId);
    },
    async openDialog(type, item) {
      const data = { userId: item._id, record: null };
      this.onRecord = true;
      (await this.$refs.recordFish) && this.$refs.recordFish.init(data, type);
    },
    async loadAllUsersListView() {
      const {
        search: name,
        page,
        itemsPerPage: limit
      } = this.options.all || {};
      this.loading = true;
      this.searchBox.all = name;
      const response = await this.getUsers({ name, page, limit });
      this.loading = false;
      if (response && response?.data && response.data?.docs) {
        this.members.all = response.data.docs;
        this.footer.all.pageCount = response.data.totalPages;
        this.footer.all.page = response.data.page;
        this.total.allDocs = response.data.totalDocs;
      }
    },
    async loadActiveUsersListView() {
      const {
        search: name,
        page,
        itemsPerPage: limit
      } = this.options.active || {};
      this.loading = true;
      this.searchBox.active = name;
      const response = await this.getUsers({
        name,
        page,
        limit,
        status: 'active'
      });
      this.loading = false;
      if (response && response?.data && response.data?.docs) {
        this.members.active = response.data.docs;
        this.footer.active.pageCount = response.data.totalPages;
        this.footer.active.page = response.data.page;
        this.total.activeDocs = response.data.totalDocs;
      }
    },
    async loadInactiveUsersListView() {
      const {
        search: name,
        page,
        itemsPerPage: limit
      } = this.options.inactive || {};
      this.loading = true;
      this.searchBox.inactive = name;
      const response = await this.getUsers({
        name,
        page,
        limit,
        status: 'inactive'
      });
      this.loading = false;
      if (response && response?.data && response.data?.docs) {
        this.members.inactive = response.data.docs;
        this.footer.inactive.pageCount = response.data.totalPages;
        this.footer.inactive.page = response.data.page;
        this.total.inactiveDocs = response.data.totalDocs;
      }
    },
    async loadDoNotMailUsersListView() {
      const {
        search: name,
        page,
        itemsPerPage: limit
      } = this.options.doNotMail || {};
      this.loading = true;
      this.searchBox.doNotMail = name;
      const response = await this.getUsers({
        name,
        page,
        limit,
        status: 'doNotMail'
      });
      this.loading = false;
      if (response && response?.data && response.data?.docs) {
        this.members.doNotMail = response.data.docs;
        this.footer.doNotMail.pageCount = response.data.totalPages;
        this.footer.doNotMail.page = response.data.page;
        this.total.doNotMailDocs = response.data.totalDocs;
      }
    },
    async loadDoNotNotifyUsersListView() {
      const {
        search: name,
        page,
        itemsPerPage: limit
      } = this.options.doNotNotify || {};
      this.loading = true;
      this.searchBox.doNotNotify = name;
      const response = await this.getUsers({
        name,
        page,
        limit,
        status: 'doNotNotify'
      });
      this.loading = false;
      if (response && response?.data && response.data?.docs) {
        this.members.doNotNotify = response.data.docs;
        this.footer.doNotNotify.pageCount = response.data.totalPages;
        this.footer.doNotNotify.page = response.data.page;
        this.total.doNotNotifyDocs = response.data.totalDocs;
      }
    },
    async loadVirtuousContactsListView() {
      const {
        search: name,
        page,
        itemsPerPage: limit
      } = this.options.virtuous || {};
      this.loading = true;
      this.searchBox.virtuous = name;
      const response = await this.getUsers({
        name,
        page,
        limit,
        status: 'virtuous',
        type: this.tableSelection,
        sort: this.sort,
        order: this.order ? 1 : 2
      });
      this.loading = false;
      if (response && response?.data && response.data?.docs) {
        this.members.virtuous = response.data.docs;
        this.footer.virtuous.pageCount = response.data.totalPages;
        this.footer.virtuous.page = response.data.page;
        this.total.virtuousDocs = response.data.totalDocs;
      }
    },
    editUserInfo(row) {
      this.selectedRow = row;
      if (this.$refs.editUserInfo) {
        this.$refs.editUserInfo.initialize(this.selectedRow);
      }
      this.onUserEdit = true;
    },
    async submitUserInfo(info) {
      try {
        this.$refs.editUserInfo.overlay = true;
        const response = await this.saveUserInfo(info);
        this.$refs.editUserInfo.overlay = false;
        if (response) {
          this.onUserEdit = false;
          notifyMessage('Your changes were submitted successfully');
          this.loadDisplayedTable();
        } else {
          notifyMessage('An unexpected error occurred');
        }
      } catch (err) {
        console.error(err);
        notifyMessage(
          'An unexpected error occurred, please contact your system administrator'
        );
      }
    },
    initEditDialog() {
      this.$refs.editUserInfo.initialize(this.selectedRow);
    },
    closeEditDialog() {
      this.onUserEdit = false;
    },
    closeRecords() {
      this.showRecords = false;
    },
    async initDialog() {
      await this.$refs.recordFish.init();
    },
    async exportFile(fileType, name = null, status = null) {
      this.overlay = true;

      const response = await this.getUsers({
        name,
        page: 1,
        limit: -1,
        status
      });

      const result = response?.data?.docs?.map((record) => {
        const address = getAddress(record);
        const fullName = `${record?.firstName} ${record?.lastName}`;
        return {
          Name: formatStr(fullName),
          Gender: record?.gender,
          Email: record?.email,
          Address: formatStr(address),
          Birthday: formatDate(record?.birthday),
          'Is Member': record?.membership?.update ? 'Yes' : 'No',
          'Member Id': record?.memberId,
          'Membership Term': formatDate(record?.membership?.expirationDate),
          Active: record?.membership?.status,
          'Date Created': formatDate(record?.dateCreated),
          'Personal Phone': formatPhone(record?.personalPhone),
          'Work Phone': formatPhone(record?.workPhone),
          'Home Phone': formatPhone(record?.homePhone)
        };
      });

      const colsWidth = [
        { wch: 30 },
        { wch: 8 },
        { wch: 20 },
        { wch: 50 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 18 },
        { wch: 8 },
        { wch: 15 },
        { wch: 20 },
        { wch: 20 },
        { wch: 20 }
      ];

      getFileDeprecated(
        result,
        colsWidth,
        fileType,
        `${this.tabs[this.tab]} Report`
      );

      this.overlay = false;
    },
    deleteAction(row) {
      notifyConfirmation(
        'You will delete this user, this action will be irreversible',
        'Are you sure to take this action?',
        async () => {
          this.overlay = true;
          try {
            const response = await this.deleteUser({ id: row?._id });
            if (response) {
              notifyMessage('User successfully removed');
              this.loadDisplayedTable();
              this.overlay = false;
              return;
            }
            notifyMessage("Something went wrong, delete action can't complete");
          } catch (error) {
            notifyMessage("Something went wrong, delete action can't complete");
          }
          this.overlay = false;
        }
      );
    },
    async loadDisplayedTable() {
      switch (this.tab) {
        case 5:
          await this.loadVirtuousContactsListView();
          break;

        case 4:
          await this.loadDoNotNotifyUsersListView();
          break;

        case 3:
          await this.loadDoNotMailUsersListView();
          break;

        case 2:
          await this.loadInactiveUsersListView();
          break;

        case 1:
          await this.loadActiveUsersListView();
          break;

        case 0:
        default:
          await this.loadAllUsersListView();
          break;
      }
    },
    async changeTabs(tab) {
      this.tab = tab;
      await this.loadDisplayedTable();
    },
    sendInvitation(row) {
      const { email } = row || {};
      if (email) {
        notifyConfirmation(
          `You're sending an invitation email to ${email}`,
          'Are you sure to proceed?',
          () => {
            notifyMessage('EMAIL SENT');
          }
        );
      }
    },
    openLinkView(row) {
      this.contact = row;
      this.linkView = true;
    },
    closeLinkView() {
      this.contact = null;
      this.linkView = false;
    },
    disconnect(row) {
      notifyConfirmation(
        'You will disconnect this account from Virtuous sync',
        'Are you sure to proceed?',
        async () => {
          const { email } = getUserInfo() || {};
          const response = await this.disconnectAccounts({
            userId: row.connectionId,
            executedBy: email
          });
          const { status } = response || {};
          if (status === 200) {
            await this.loadVirtuousContactsListView();
            notifyMessage('Successfully disconnected');
          }
        }
      );
    },
    async connectionDone() {
      await this.loadDisplayedTable();
      this.closeLinkView();
    },
    async sortBy(head) {
      this.virtuous_headers.forEach((item) => {
        if (item.sort === head?.sort) {
          item.order = !head?.order;
        } else {
          item.order = true;
        }
      });
      const header = this.virtuous_headers.find(
        (item) => item?.sort === head?.sort
      );
      this.sort = header.sort;
      this.order = header.order;
      await this.loadVirtuousContactsListView();
    },
    async notInSystem(contact) {
      try {
        const { virtuousId: contactId } = contact;
        const { email: executedBy } = getUserInfo() || {};
        await this.hideContact({ contactId, executedBy });
        await this.loadVirtuousContactsListView();
      } catch (error) {
        notifyError(
          'Something went wrong. Try again or contact the developer team'
        );
      }
    },
    async backInSystem(contact) {
      try {
        const { virtuousId: contactId } = contact;
        const { email: executedBy } = getUserInfo() || {};
        await this.exposeContact({ contactId, executedBy });
        await this.loadVirtuousContactsListView();
      } catch (error) {
        notifyError(
          'Something went wrong. Try again or contact the developer team'
        );
      }
    }
  },
  watch: {
    tableSelection: {
      handler: 'loadVirtuousContactsListView'
    },
    onTag(value) {
      if (!value) {
        this.$refs.tagFish.reset();
      }
    },
    onRecapture(value) {
      if (!value) {
        this.$refs.recaptureFish.reset();
      }
    },
    onRelease(value) {
      if (!value) {
        this.$refs.releaseFish.reset();
      }
    },
    'options.all': {
      handler: 'loadAllUsersListView'
    },
    'options.active': {
      handler: 'loadActiveUsersListView'
    },
    'options.inactive': {
      handler: 'loadInactiveUsersListView'
    },
    'options.doNotMail': {
      handler: 'loadDoNotMailUsersListView'
    },
    'options.doNotNotify': {
      handler: 'loadDoNotNotifyUsersListView'
    },
    'options.virtuous': {
      handler: 'loadVirtuousContactsListView'
    }
  }
};

const HEADERS = [
  {
    text: 'Name',
    align: 'start',
    sortable: false,
    filterable: true,
    value: 'fullName',
    width: '200px'
  },
  {
    text: 'Member ID',
    align: 'center',
    sortable: false,
    filterable: true,
    value: 'memberId',
    width: '100px'
  },
  {
    text: 'Gender',
    align: 'start',
    sortable: false,
    value: 'gender',
    width: '100px'
  },
  {
    text: 'Birthday',
    align: 'start',
    sortable: false,
    value: 'birthday',
    width: '100px'
  },
  {
    text: 'Email',
    align: 'start',
    sortable: false,
    value: 'email'
  },
  {
    text: 'Address',
    align: 'start',
    sortable: false,
    value: 'fullAddress',
    width: '300px'
  },
  {
    text: 'Personal Phone',
    align: 'start',
    sortable: false,
    filterable: true,
    value: 'personalPhone',
    width: '150px'
  },
  {
    text: 'Work Phone',
    align: 'start',
    sortable: false,
    filterable: true,
    value: 'workPhone',
    width: '150px'
  },
  {
    text: 'Home Phone',
    align: 'start',
    sortable: false,
    filterable: true,
    value: 'homePhone',
    width: '150px'
  },
  { text: 'Actions', value: 'actions', sortable: false, width: '250px' }
];

const VIRTUOUS_HEADERS = [
  {
    text: 'Name',
    align: 'start',
    sortable: false,
    filterable: true,
    value: 'fullName',
    width: '200px'
  },
  {
    text: 'Virtuous ID',
    align: 'center',
    sortable: false,
    filterable: true,
    value: 'virtuousId',
    width: '100px',
    sort: 'id',
    order: true
  },
  {
    text: 'Membership Expiration',
    align: 'start',
    sortable: false,
    value: 'membership.expirationDate',
    width: '100px',
    sort: 'expiration',
    order: true
  },
  {
    text: 'Gender',
    align: 'start',
    sortable: false,
    value: 'gender',
    width: '100px'
  },
  {
    text: 'Birthday',
    align: 'start',
    sortable: false,
    value: 'birthday',
    width: '100px'
  },
  {
    text: 'Email',
    align: 'start',
    sortable: false,
    value: 'email'
  },
  {
    text: 'Address',
    align: 'start',
    sortable: false,
    value: 'fullAddress',
    width: '300px'
  },
  {
    text: 'Phone',
    align: 'start',
    sortable: false,
    filterable: true,
    value: 'phone',
    width: '150px'
  },
  { text: 'Actions', value: 'actions', sortable: false, width: '250px' }
];
</script>

<style lang="css">
.v-dialog--active {
  overflow-y: hidden !important;
}
.header_size {
  min-width: 150px;
}
.head_div {
  width: max-content;
}

.dropdown input {
  min-width: 0 !important;
}

.dropdown .v-select__selections {
  width: min-content;
}

.dropdown .v-input__slot {
  border: none;
  padding: 0;
  border-bottom: 1px solid black;
}
</style>
