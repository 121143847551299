<template>
  <v-container fluid>
    <v-dialog v-model="onCreateBoat" max-width="400px" :fullscreen="!full">
      <new-boat ref="newBoat" @close="onCreateBoat = false" />
    </v-dialog>
    <v-dialog v-model="onCreateSpecie" max-width="560px" :fullscreen="!full">
      <edit-item
        ref="editItem"
        @cancel="onCreateSpecie = false"
        @save="saveSpecie"
        :saving="saving"
      />
    </v-dialog>
    <v-form ref="releaseForm" @submit="submit" :disabled="loading" class="mt-5">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <date-picker
            v-model="input.createdDate"
            label="RELEASE DATE"
            @clear="input.createdDate = null"
            :rules="rule"
          />
        </v-col>
      </v-row>

      <coordinates-form
        v-model="input.coordinates"
        ref="coordsForm"
        @change="setMarker"
        previous-locations
        @activatePreviousLocations="
          (value) => (previousLocationsActive = value)
        "
      />

      <v-row>
        <v-col cols="12">
          <fishing-locations
            ref="fishingLocations"
            @coords="setCoords"
            @validate="notifyInvalidCoords"
            draggable
            :input="input"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="9">
          <auto-complete-location
            label="LOCATION NAME"
            v-model="input.coordinates"
            @locate="(coords) => (!validCoordinates ? setMarker(coords) : null)"
            @ocean="(value) => (input.capture.ocean = value)"
            addNew
            :rules="rule"
            :coordinates="input.coordinates"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <auto-complete
            label="OCEAN"
            :rules="rule"
            itemType="oceans"
            v-model="input.capture.ocean"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-end">
          <v-bottom-sheet
            v-model="sheet"
            persistent
            :fullscreen="!full"
            :scrollable="!full"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn small color="primary" v-bind="attrs" v-on="on">
                Add New User
              </v-btn>
            </template>
            <new-user ref="newUser" @close="closeEditMode" />
          </v-bottom-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            label="ANGLER"
            v-model="input.angler"
            addNew
            @add="sheet = true"
            editable
            @edit="editUser(input.angler)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            label="CAPTAIN"
            v-model="input.captain"
            addNew
            @add="sheet = true"
            editable
            @edit="editUser(input.captain)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            label="FIRST MATE"
            v-model="input.firstMate"
            addNew
            @add="sheet = true"
            editable
            @edit="editUser(input.firstMate)"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            label="SECOND MATE"
            v-model="input.secondMate"
            addNew
            @add="sheet = true"
            editable
            @edit="editUser(input.secondMate)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="0" sm="3" />
        <v-col cols="12" sm="6">
          <auto-complete
            label="BOAT NAME"
            itemType="boats"
            v-model="input.boat"
            @new="createBoat"
            add
          />
        </v-col>
        <v-col cols="0" sm="3" />
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <auto-complete
            ref="specieCombo"
            label="SPECIES"
            :rules="releaseValidation"
            itemType="species"
            v-model="specie"
            :exceptions="
              Array.isArray(input.capture.fish) ? input.capture.fish : []
            "
            :ocean="input.capture.ocean"
            add
            @new="launchCreateSpecieDialog"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="quantity"
            label="QUANTITY"
            :rules="releaseValidation.concat(maxLimit_100)"
            v-mask="'###'"
            maxLength="3"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn color="primary" block @click="newReleaseFish" class="mb-5">
            Add New
          </v-btn>
        </v-col>
      </v-row>
      <v-scroll-y-transition>
        <v-banner
          v-if="!!hintMsg"
          :color="`${hintMsg.includes('NEW') ? 'light-blue' : 'red'} lighten-5`"
        >
          {{ hintMsg }}
        </v-banner>
      </v-scroll-y-transition>
      <v-chip-group
        v-if="Array.isArray(input.capture.fish)"
        show-arrows
        class="mb-5"
      >
        <v-chip
          v-for="(fish, i) in input.capture.fish"
          :key="i"
          color="primary"
          large
          outlined
        >
          <span class="chip_label">{{ fish.name }}: {{ fish.qty }}</span>
          <v-icon large right class="chip_icon" @click="deleteItem(i)"
            >mdi-delete</v-icon
          >
        </v-chip>
      </v-chip-group>

      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="input.relLines"
            label="LINES IN"
            type="time"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="input.relLines0"
            label="LINES OUT"
            type="time"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="input.relNumLi"
            v-mask="'##'"
            maxLength="2"
            :rules="maxLimit"
            label="TOTAL LINES"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <auto-complete
            label="BAIT TYPE"
            itemType="baits"
            v-model="input.capture.bait"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <auto-complete
            label="HOOK TYPE"
            itemType="hooks"
            v-model="input.capture.hook.name"
          />
          <v-checkbox
            v-model="input.capture.hook.isRemoved"
            hide-details
            label="Hook Removed"
            class="shrink mr-2 mt-0"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <!-- <v-checkbox
            v-model="input.sharkEncounter"
            hide-details
            label="Shark Encounter"
            class="shrink mr-2 mt-0"
          /> -->
          <v-switch
            inset
            label="Shark Encounter"
            v-model="input.sharkEncounter"
            hide-details
            v-if="input.sharkEncounter !== null"
          ></v-switch>
          <h4 class="mt-2" v-else>Shark Encounter: N/A</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            auto-grow
            rows="1"
            label="NOTES"
            v-model="input.capture.notes"
            outlined
            clearable
            dense
            counter
            :rules="notesRule"
            hint="Don't forget to add the Release Card Number in this section"
            persistent-hint
            class="hint-msg"
            :class="{ focused: focused }"
            ref="notes"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import AutoComplete from '../../../../components/appComponents/AutoComplete.vue';
import AutoCompleteUsers from '../../../../components/appComponents/AutoCompleteUsers.vue';
import {
  getUserInfo,
  notifyMessage,
  setRequestedUserData
} from '../../../../helpers/handler';
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel
} from '../../viewmodels';
import NewBoat from './NewBoat.vue';
import NewUser from './NewUser.vue';
import FishingLocations from '../../../map/FishingLocations.vue';
import DatePicker from '../../../../components/appComponents/DatePicker.vue';
import AutoCompleteLocation from '../../../../components/appComponents/AutoCompleteLocation.vue';
import CoordinatesForm from './CoordinatesForm.vue';
import EditItem from './EditItem.vue';
import moment from 'moment';
export default {
  name: 'release-form',
  components: {
    AutoCompleteUsers,
    AutoComplete,
    NewUser,
    NewBoat,
    FishingLocations,
    DatePicker,
    AutoCompleteLocation,
    CoordinatesForm,
    EditItem
  },
  props: {
    type: String,
    fillAngler: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    maxLimit_100() {
      return [(v) => !v || v <= 100 || 'Maximum 100 allowed'];
    },
    releaseValidation() {
      return [
        () =>
          this.input.capture.fish?.length > 0 ||
          !!this.quantity ||
          !!this.specie?.id ||
          ''
      ];
    },
    maxLimit() {
      return [(v) => !v || v <= 10 || 'Maximum 10 allowed'];
    },
    minLimit() {
      return [(v) => !v || v >= 0 || 'Negative values not allowed'];
    },
    hintMsg() {
      const msg = this.submitted
        ? 'You have to add at least one record'
        : this.quantity && this.specie?.id && !this.input.capture.fish?.length
        ? 'You have to click on ADD NEW to add this record'
        : '';
      return msg;
    },
    validCoordinates() {
      return this.$refs.coordsForm && this.$refs.coordsForm.validate();
    }
  },
  data: () => ({
    input: window.structuredClone(initInput),
    onCreateBoat: false,
    onCreateSpecie: false,
    sheet: false,
    editUserId: null,
    loading: false,
    saving: false,
    rule: [(v) => !!v || 'Required'],
    specie: null,
    quantity: null,
    submitted: false,
    notesRule: [(v) => !v || v?.length <= 300 || 'Max 300 characters'],
    focused: false,
    invalidCoords: false,
    prevUserList: [],
    previousLocationsActive: false
  }),
  beforeUpdate() {
    if (!Array.isArray(this.input.capture.fish)) {
      this.input.capture.fish = [];
    }
  },
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapActions('entry', ['addItem']),
    async initRecord(record) {
      this.$refs.releaseForm && (this.$refs.releaseForm.scrollTop = 0);
      this.loading = true;
      this.quantity = null;
      this.specie = {};
      this.resetAll();
      if (!record) {
        if (this.fillAngler) {
          const userInfo = getUserInfo();
          const { data } = (await this.getUser({ id: userInfo?.id })) || {};
          if (data) {
            this.input.angler = data;
          }
        }
        this.input['sharkEncounter'] = false;
      } else {
        this.input = { ...this.input, ...record };
        if (moment(this.input.enteredDate).isBefore(moment('10/01/2022'))) {
          if (!('sharkEncounter' in this.input)) {
            this.input['sharkEncounter'] = null;
          }
        } else {
          if (!this.input['sharkEncounter']) {
            this.input['sharkEncounter'] = false;
          }
        }
      }
      this.loading = false;
    },
    submit() {
      let valid = this.$refs.releaseForm.validate() && this.validCoordinates;
      if (this.input.capture.fish?.length < 1) {
        valid = false;
        this.submitted = true;
        setTimeout(() => {
          this.submitted = false;
        }, 4000);
      }
      if (this.invalidCoords) {
        valid = null;
        this.notifyInvalidCoords(false);
      }
      const data = setRequestedUserData(this.input);
      return { valid, data };
    },
    notifyInvalidCoords(isWater) {
      if (isWater) {
        this.invalidCoords = false;
        return;
      }
      this.invalidCoords = true;
      notifyMessage('Invalid coords, no seawater surface');
    },
    createBoat() {
      this.$refs.newBoat && this.$refs.newBoat.init();
      this.onCreateBoat = true;
    },
    resetAll() {
      this.input = window.structuredClone(initInput);
      this.$refs.releaseForm && this.$refs.releaseForm.resetValidation();
    },
    resetInfo() {
      const { capture, sharkEncounter, relLines, relLines0, relNumLi } =
        window.structuredClone(initInput);
      this.input.relLines = relLines;
      this.input.relLines0 = relLines0;
      this.input.relNumLi = relNumLi;
      this.input.capture.fish = [];
      this.input.capture.notes = capture.notes;
      this.input.sharkEncounter = sharkEncounter;
      this.$refs.releaseForm && this.$refs.releaseForm.resetValidation();
    },
    setCoords(data) {
      this.$refs.fishingLocations &&
        this.$refs.fishingLocations.getDescription(data);
      data && (this.input.coordinates = { ...this.input.coordinates, ...data });
    },
    newReleaseFish() {
      const qty = Number(this.quantity) || 0;
      if (!!this.specie?.id?.length && !!qty && !Number.isNaN(qty)) {
        this.input.capture.fish.push({
          id: this.specie.id,
          name: this.specie.name,
          qty,
          acronym: this.specie.acronym
        });
        this.quantity = null;
        this.specie = {};
      }
    },
    getSpeciesList() {
      this.$refs.specieCombo && this.$refs.specieCombo.getFilterItems();
    },
    deleteItem(v) {
      this.input.capture.fish.splice(v, 1);
      this.$refs.specieCombo && this.$refs.specieCombo.getFilterItems();
    },
    focusNotes() {
      if (this.$refs.notes) {
        this.$refs.notes.focus();
        this.focused = true;
        setTimeout(() => {
          this.focused = false;
        }, 1500);
      }
    },
    setBoatName(name) {
      if (name) {
        if (this.input?.boatName && !this.input?.boat?.name) {
          this.input.boat.name = this.input.boatName;
        }

        if (this.input?.boat?.name) {
          this.input.boatName = this.input.boat.name;
        }
      } else {
        this.input.boatName = null;
        this.input.boat['name'] = null;
      }
    },
    setMarker(coordinates = null) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }

      this.input.coordinates = { ...this.input.coordinates, ...coordinates };
    },
    editUser(user = {}) {
      this.sheet = true;
      this.editUserId = user?.id || user?._id;
    },
    closeEditMode() {
      this.sheet = false;
      this.editUserId = null;
    },
    async saveSpecie(data) {
      this.saving = true;
      const date = new Date();
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const user = getUserInfo();
      const response = await this.addItem({
        item: 'species',
        data: {
          ...data,
          enteredBy: user?.email,
          enteredDate: date.toISOString(),
          updatedBy: user?.email,
          updatedDate: date.toISOString()
        }
      });
      this.saving = false;
      const { success } = response?.data || {};
      notifyMessage(
        success
          ? 'Successful operation'
          : 'Something went wrong, please try again'
      );
      if (success) {
        this.$refs.specieCombo &&
          (await this.$refs.specieCombo.getFilterItems());
      }
      this.onCreateSpecie = false;
    },
    launchCreateSpecieDialog() {
      this.onCreateSpecie = true;
      setTimeout(() => {
        this.$refs.editItem && this.$refs.editItem.initialize('species');
      }, 10);
    },
    initEditUser(value) {
      if (value) {
        setTimeout(async () => {
          if (this.$refs.newUser) {
            await this.$refs.newUser.init(this.editUserId);
          }
        }, 100);
      }
    },
    async setPreviousLocations(input, forceRender = false) {
      const { angler, captain, firstMate, secondMate } = input;
      const userList = [
        angler?._id,
        captain?._id,
        firstMate?._id,
        secondMate?._id
      ].filter(Boolean);

      if (!userList.length) {
        this.$refs.fishingLocations.clearLocationMarkers();
        return;
      }

      if (!forceRender && userList.length === this.prevUserList.length) {
        const newUsers = userList.filter(
          (id) => !this.prevUserList.includes(id)
        );
        if (!newUsers.length) {
          return;
        }
      }

      if (this.$refs.fishingLocations) {
        await this.$refs.fishingLocations.setLocationMarkers(userList);
        this.prevUserList = userList;
      }
    }
  },
  watch: {
    'input.boatName': {
      handler: 'setBoatName'
    },
    'input.boat.name': {
      handler: 'setBoatName'
    },
    'input.capture.fish': {
      handler: 'getSpeciesList',
      deep: true
    },
    sheet: {
      handler: 'initEditUser',
      immediate: true
    },
    input: {
      handler: async function (input) {
        if (!this.previousLocationsActive) {
          return;
        }

        await this.setPreviousLocations(input);
      },
      deep: true
    },
    previousLocationsActive: {
      handler: async function (value) {
        if (!value) {
          this.$refs.fishingLocations.clearLocationMarkers();
          return;
        }

        await this.setPreviousLocations(this.input, value);
      }
    }
  }
};

const initInput = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
  ...{ boatName: null }
};
</script>

<style>
.hint-msg div[class='v-messages__message'] {
  line-height: 18px !important;
  font-size: 15px !important;
}

.focused fieldset {
  border: 2px solid red;
  color: red !important;
}

.v-input.v-input--switch--inset {
  margin-top: 4px;
}

.v-input.v-input--switch--inset .v-input--switch__track::after {
  content: 'No';
  color: #333333;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 5px;
  margin-top: 2px;
}

.v-input.v-input--switch--inset.v-input--is-label-active.v-input--is-dirty
  .v-input--switch__track::after {
  content: 'Yes';
  font-size: 8px;
  /* font-weight: bold; */
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 6px;
  margin-top: 3px;
  color: white;
}
</style>
