<template>
  <v-card flat>
    <v-row class="text-center mb-2" v-if="visible">
      <v-col cols="12" sm="6" md="3">
        <v-chip class="ml-2" outlined pill color="primary">
          Total Events: {{ totalCount || 0 }}
        </v-chip>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-chip class="ml-2" outlined pill color="primary">
          Tags Recaptured: {{ tagsRecaptured || 0 }}
        </v-chip>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-chip class="ml-2" outlined pill color="primary">
          Events as angler: {{ totalEventsAsAngler || 0 }}
        </v-chip>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-chip class="ml-2" outlined pill color="primary">
          Events as captain: {{ totalEventsAsCaptain || 0 }}
        </v-chip>
      </v-col>
    </v-row>
    <v-tabs
      center-active
      v-model="selection"
      next-icon="mdi-chevron-right"
      prev-icon="mdi-chevron-left"
      show-arrows
      @change="v => $emit('change', v)"
    >
      <v-tab v-for="tab in tabs" :key="tab" @click="$emit('type', tab)">
        {{ tab }}
      </v-tab>
      <v-spacer />
      <v-btn
        dark
        depressed
        color="primary"
        @click="addNew"
        v-if="visible && full"
      >
        <v-icon left dark>mdi-plus</v-icon>
        New {{ caption }}
      </v-btn>
    </v-tabs>
    <v-btn
      dark
      depressed
      block
      color="primary"
      class="my-5"
      @click="addNew"
      v-if="visible && !full"
    >
      <v-icon left dark>mdi-plus</v-icon>
      New {{ caption }}
    </v-btn>
    <v-tabs-items v-model="selection">
      <slot name="tab-items" v-bind:selection="selection"></slot>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'tab-menu',
  props: {
    tabs: Array
  },
  data: () => ({
    selection: 0,
    totalCount: 0,
    tagsRecaptured: 0,
    totalEventsAsAngler: 0,
    totalEventsAsCaptain: 0
  }),
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    caption() {
      return String(this.tabs[this.selection] || '')?.slice(0, -1);
    },
    visible() {
      const title = this.tabs[this.selection];
      return (
        String(title || '')?.includes('Tag') ||
        String(title || '')?.includes('Release') ||
        String(title || '')?.includes('Recapture')
      );
    }
  },
  methods: {
    ...mapActions('users', ['getUser']),
    addNew() {
      this.$emit('add', this.tabs[this.selection]);
    },
    async getTotalCount(id) {
      if (!id) return;
      const response = await this.getUser({ id });
      if (response?.data) {
        const {
          totalEventsGeneral,
          totalTagsRecaptured,
          totalEventsAsAngler,
          totalEventsAsCaptain
        } = response.data;
        this.totalCount = totalEventsGeneral || 0;
        this.tagsRecaptured = totalTagsRecaptured || 0;
        this.totalEventsAsAngler = totalEventsAsAngler || 0;
        this.totalEventsAsCaptain = totalEventsAsCaptain || 0;
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  padding: 0;
}
</style>
