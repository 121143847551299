<template>
  <v-card fluid>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title> New </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="submit"> Save </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="boatForm" class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Name"
              :rules="rule"
              v-model="input.name"
              hide-details
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12">
            <auto-complete-users label="Owner" v-model="input.owner" />
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Model"
              v-model="input.model"
              hide-details
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Make"
              v-model="input.make"
              hide-details
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Length"
              v-model="input.length"
              hide-details
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="input.membership"
              label="Anglers receive certificate"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import AutoCompleteUsers from '../../../../components/appComponents/AutoCompleteUsers.vue';
import { BoatsViewModel } from '../../viewmodels/index';
import { notifyMessage } from '../../../../helpers/handler';
export default {
  components: { AutoCompleteUsers },
  name: 'new-user',
  data: () => ({
    input: new BoatsViewModel(),
    rule: [(v) => !!v || 'Required'],
    emailRule: [(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid']
  }),
  methods: {
    ...mapActions('entry', ['addItem']),
    async submit() {
      const valid = this.$refs.boatForm && this.$refs.boatForm.validate();
      if (valid) {
        const {
          name,
          owner: { firstName, lastName },
          model,
          make,
          length,
          membership
        } = this.input;
        const data = { name, owner: { firstName, lastName } };
        model && (data.model = model);
        make && (data.make = make);
        length && (data.length = length);
        membership && (data.membership = membership);
        const { data: response } =
          (await this.addItem({ item: 'boats', data })) || {};
        notifyMessage(
          response?.success
            ? 'Boat successfuly created'
            : 'Boat already exists or something went wrong'
        );
        this.$emit('close');
      }
    },
    init() {
      this.$refs.boatForm && this.$refs.boatForm.reset();
    }
  }
};
</script>
